.informatica {
    overflow-x: hidden;
}

.img-one {
    width: auto;
    max-height: 30vw;
}

.info-intro {
    display: flex;
    width: 100vw;
    height: 550px;
    background-position: cover;
    background-size: cover;
    background-repeat: no-repeat;
    font-family: 'Lato', sans-serif;
    flex-direction: column;
    align-items: center;
    background-image: url(../../assets/images/comp.jpeg);
}

.info-section  {    
    display: flex;
    flex-direction: row;
    background: rgb(240 241 242 / 60%);
    border-radius: 0.2em;
    color: #14213D;
    width: 95vw;
    margin: 5em 0 0 5em;
    padding: 5vw;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
}

.inf-back1 {
    width: 60vw;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-content: space-around;
    text-align: right;
    font-size: 1em;
}

.info-intro p {
    text-align: left;
    font-size: 1.2em;
    width: 80vw;
    text-transform: none;
    max-width: 90vw;
    line-height: 1.6em;
    font-weight: 400;
}

#info-title {
    text-align: center;
    letter-spacing: 1.0em;    
    text-transform: uppercase;
    color: #14213D;
    font-weight: 400;
}

.info-intro h1{
    margin: 2rem;
    background: rgb(240 241 242 / 52%);
    width: 100vw;
}
.btn-cont {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;}

.info-btn-intro {
    padding: 10px;
    font-size: 1em;
    margin: 10px 20px 5px 0;
    background-color: transparent;
    border: 2px solid #14213D;
    border-radius: 0.2em;
    text-decoration: none;
}

.info-btn-intro a {
    text-decoration: none;
    color: #14213D;
    margin-left: 8px;
}

.info-btn-intro:hover {
    color: orange;
    opacity: 0.5;
}

.info-catalog-software {
    text-align: left;
    padding: 3% 5%;
}

.info-catalog-software h3,
.info-catalog-intro h3 {
    letter-spacing: 0.2em;
    margin-bottom: 0.1rem;
    margin-top: 2rem;
    color: grey;
    font-weight: 400;
}

.info-catalog-software h4,
.info-catalog-intro h4
 {
    color: #14213D;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1em;
}

.info-catalog-software p,
.info-catalog-intro p {
    color: #14213D;
    font-weight: 300;
    font-size: 1.3em;
    line-height: 2em;
    text-align: justify;
}

.info-catalog-intro h3 {
    margin-bottom: 0.8em;
}

.info-catalog-intro h4 {
    margin-bottom: 1em;
}


.info-catalog-intro {
    text-align: left;
    padding: 3% 5%;}

.logo-software {
    width: 30px;
    height: 30px;
}

.info-catalog-software a {
    text-decoration: none;
    color: orange;
    font-weight: 700;
}
.info-catalog-software a:hover {
    text-decoration: underline;
}

.inf-final {
    background-color: rgba(240, 241, 242);
    height: 20rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    padding: 0 20em;
    color: white;
    border-bottom: 1px solid white;
}

.inf-final h3 {
    font-weight: 200;
    letter-spacing: 0.5em;
}

.info-icon {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    max-width: 70vw;
}

.info-icon p {
    margin-top: 0.5em;
    color: grey
}

.icon-cont {
    display: flex;
    justify-content: center; 
    align-items: center; 
    border-radius: 50%;
    color: orange;
    font-size: 60px;
    width: 120px; 
    height: 120px;
    background-color: rgba(0, 0, 0, 0.05);
}


@media (max-width: 920px) {

#info-title {
    letter-spacing: 0.5em;
    }
    .info-intro p {
        font-size: 1em;
    }

    .info-catalog-software h3,
    .info-catalog-intro h3 {
        letter-spacing: 0.5em;
        font-size: 1.2em;
        text-align: center;
    }
    .info-catalog-software p,
    .info-catalog-intro p {
        color: #14213D;
        font-weight: 300;
        font-size: 1em;
        text-align: justify;
    }

    .inf-final{
        padding: 0 9em;
        height: 19rem;    
    }

}

@media (max-width: 550px) {
    .info-intro {
    height: 450px;
    }
    #info-title {
        letter-spacing: 0.2em;
    }

    .info-section {
        margin: 3.5em 0 0 2em;
    }

    .info-section p {
        font-size: 0.9em;
        width: 100%;
    }

    .inf-back1 {
        width: 80vw;
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: flex-start;
        text-align: justify;
        font-size: 0.9em;
    }

    .info-catalog-intro,
    .info-catalog-software {
        padding: 10% 5%;
    }

    .info-btn-intro {
        padding: 4px;
        font-size: 0.7em;
        margin: 10px 20px 5px 0;
    }

    .info-catalog-software h4,
    .info-catalog-intro h4 {
        margin-top: 1em;
        letter-spacing: 0.5em;
        text-align: center;
    }
    .info-catalog-software h3,
    .info-catalog-intro h3 {
        letter-spacing: 0.2em;
        text-align: center;
    }
    .info-catalog-software p,
    .info-catalog-intro p {
        font-size: 0.9em;
        line-height: 2em;
    }

    .inf-final {
        flex-direction: column;
        width: 100vw;
        padding: 0;
        justify-content: center;
    }

    .info-icon {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    max-width: 100vw;
    text-decoration: none;
    }

    .icon-cont {
        font-size: 30px;
        width: 65px;
        height: 65px;
    }
    
}
