.error {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.not-found {
    display: flex;
    flex-direction: column;
    text-align: center;
    color: #14213D;
    align-content: space-around;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
}


#fourofour {
    font-size: 16rem;
    color: grey;
    font-weight: bold;
    margin-top: 40px;
    letter-spacing: -2px;
}

.error a {
    text-decoration: none;
    margin-top: -3;
    text-align: center;
}

.error h1 {
    letter-spacing: 0.7rem;
    color: grey;
}

#err-p {
    color: grey;
}

.err  {
    text-align: center;
    transition: none;
    font-size: x-large;
    color: #14213D;
    font-weight: 900;
    }
    
    .err span {
        color: orange;
        margin-right: 12px;
        font-size: 1em;
        border: none;
      }

    .err #span2 {
        margin-left: 12px;
    }

    .err:hover {
        opacity: 0.2;
    }


    @media (min-width: 1000px) {
        #fourofour {
            font-size: 20rem;
    }
    }

    @media (max-width: 500px) {

        .not-found{
        max-width: 90vw;
        }

        #fourofour {
        font-size: 10rem;
        }

        .error h1 {
          font-size: 1.2rem;
          font-weight: 600;
        }
    }

    @media (max-width: 280px) {
        .not-found {
          max-width: 90vw;
        }
      
        #fourofour {
          font-size: 7rem;
        }
      }


