.scroll-container {
  overflow-x: hidden;
}

.scroll-top {
    position: fixed;
    font-size: 3em;
    bottom: 2em;
    right: 0.5em;
    background-color: transparent;
    padding: 20px;
    border-radius: 100%;
    z-index: 999;
  }

  #arrow {
    color: orange;
    border-radius: 50%;
    box-shadow: 3px 4px 3px #999;
  }
