
.acco-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
}

#al-intro {
    text-transform: uppercase;
    text-align: center;
    position: absolute;
    letter-spacing: 0.5em;
    color: #14213D;
    z-index: 99;
    margin: 0px;
    margin-top: 2rem;
    width: 100vw;
    overflow-x: hidden;
    background: rgb(240 241 242 / 42%);
}

.al-text  {
    display: flex;
    flex-direction: row;
    width: 100vw;
    padding: 5vw;
    align-content: space-around;
    justify-content: space-evenly;
    align-items: center;
    background-color: #14213D;
    font-weight: 300;
    overflow-x: hidden;

}

.al-text p {
    font-size: 1.3em;
    width: 40vw;
    text-align: center;
    margin: 0px;
    color: white;
}

#al-icon-con {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    padding: 12px;
    background-color: white;
    display:flex;
}

#al-icon {
    width: 150px;
    height: auto;
    display: block;
    margin:auto;
    font-size: 150px;
    padding: 20px;
    color: #205375;
    z-index: 99;
}

.acco-carousel {
    position: relative;
}

.cards-al {
    width: 100vw;
}

.al-houses h4{
    margin: 3em 0 2em;
    letter-spacing: 1.2em;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    color: #14213D;
    text-align: center;
    padding: 0;
}

@media (max-width: 990px) {
    #al-icon {
        width: 120px;
    }
    #al-icon-con {
        width: 120px;
        height: 120px;
    }
    .al-text p {
        font-size: 1em;
    }
}

@media (max-width: 660px) {
.al-houses h4 {
    letter-spacing: 0.5em;
}

#al-intro {
    letter-spacing: 0.3em;
    padding: 0 3em
}
}

@media (max-width: 500px) {

    #al-icon-con {
        width: 120px;
        height: 120px;
        margin: 20px 0 5px;
    
    }
    
    #al-icon {
      width: 120;
      padding: 20px;
    }    
    
    .al-text {
        flex-direction: column;
    }
    .al-text p {
        font-size: 0.9em;
        width: 80vw;
        margin: 30px;
    }
    }

 @media (max-width: 320px) {
    #al-intro {
        letter-spacing: 0.1;
        font-size: 1.2em;
    }
 }