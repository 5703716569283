

.contact-container {
    max-width: 960px;
    margin: auto;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
    background-color: lightgrey;
    box-shadow: 0 0 1rem hsla(0, 0, 0, 16%);
    border-radius: 0.2em;
    overflow: hidden;
}

.form-container {
    padding: 20px;
}

.form-container h2 {
    padding-bottom: 15px;
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 1rem;
    text-transform: uppercase;
    text-align: center;

}

.contact-form {
    display: grid;
    row-gap: 1rem;
    width: 100%;

}

.contact-form input,
.contact-form textarea,
.contact-form select
{
    width: 100%;
    border: none;
    outline: none;
    padding: 10px;
    font-size: 0.9rem;
    color: black;
    border-radius: 0.4rem;
}

.contact-form textarea {
    resize: none;
    height: 200px;
}

.contact-form #submit{
    width: 75%;
    padding: 10px;
    border: none;
    outline: none;
    background: rgb(222, 219, 219);
    font-size: 1rem;
    text-transform: uppercase;
    cursor: pointer;
    color: grey;
    border: 2px solid grey;
    border-radius: 0.4rem;
}

.contact-form #submit:hover {
    background: orange;
    color: white;
    border: 2px solid orange;
    transition: 0.3s all linear;
}

.contact-form #submit:active {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);  
    color: white;
    background-color: grey;
}

.map iframe {
    width: 100%;
    height: 100%;
}

.btnsForm {
    display: inline-flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
}

@media (max-width:964px) {
    .contact-container {
        margin: 0 auto;
        width: 90%;
    }

    .contact-form #submit {
        max-width: 65%
    }
}   

@media (max-width:700px) {
    .contact-container {
        grid-template-columns: 1fr;
        margin-top: 2rem;
    }

    .map iframe {
        height: 400px;
    }

}  
