.cards-background {
    padding: 0 5%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.card-col {
  flex: 0 0 33.33%; /* Define o tamanho dos cards em 33.33% da largura do container */
  padding: 10px;
  display: flex;
  justify-content: center;
}

#cards {
    padding: 10%;
    background-color: rgb(240, 241, 242);
    border: none ;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    max-width: 500px;
    height: auto;
    margin-bottom: 20px; /* Adicione margem inferior para separar os cards */
}

.carousel-image {
    max-width: 80vw;
    height: auto;
    border-radius: 0px;
}

/* Card Title */
#card-header {
  border-bottom: 1px solid orange
}

/* Card Text */
#card-bd {
  padding: 0;
}

.card-header {
  font-weight: bold;
  text-transform: uppercase;
  background-color: none important!;
}

#card-desc {
  margin: 5%;
  max-height: 25ch;
  text-align: justify;
  overflow-y: scroll;
}

#card-footer {
  font-size: smaller;
  margin: 0;
  color: grey;
  background-color: none;
}

/* Carousel Controler*/

  .carousel .carousel-indicators [data-bs-target]{
    margin: 0em 0.3em;
    width: 10px;
    height: 10px;    
    border-radius: 100%;
    background-color: white
  }

  /* Card Icons */
  .card-icon {
    color: orange;
  }

  /* Button */
  .btn-container {
    display: flex;
    flex-direction: column;
  }

  .modal-btn {
    padding: 10px 0 0 0; 
    border: none;
    border-radius: 0.2em;
    text-align: center;
    font-family: 'Lato', sans-serif;
    color: grey;
    font-weight: 700;
    font-size: 30px;
  }

  .modal-btn:hover {
    color: orange;
  }

  /* Responsive Query */

  @media (max-width: 760px) {
    .cards-background {
      display: flex;
      align-content: center;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      padding: 2vw 0 ;
    }
    #cards {
      margin: 5vw;
    }
    .col {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0px;
    }

  }

  @media (min-width: 2000px) {

    #cards {
      border-radius: 5px;
      width: 30vw;
      height: auto;
  } 
}
  