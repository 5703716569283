.cardsAl {
  padding: 1em 5%;

}

.cards-background-al {
  padding: 1.2em;
  border: none;
}

#cards-al {
  padding: 10%;
  max-width: 500px;
  color: #1f335f85;
  border: none;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  height: auto;
}

/* Card Title */
#card-header-al {
  text-align: left;
  font-size: 1em;
  color: #14213D;
  border-bottom: 1px solid #205375;
  ;
}

/* Card Text */

.head-al {
  margin: 5%;
}

#card-bd {
padding: 0;
}

#al-facilities  {
  text-transform: none;
}

.card-header {
font-weight: bold;
text-transform: uppercase;
background-color: none important!;
}

#card-desc {
margin: 5%;
}

#card-body-al {
  border-radius: none;
  margin: 0px;
  padding: 0px;
}

/* Carousel Controler*/

.carousel .carousel-indicators [data-bs-target]{
  margin: 0em 0.3em;
  width: 10px;
  height: 10px;    
  border-radius: 100%;
  background-color: white
}

/* Card Icons */

#al-icons {
  font-size: 37px;
  text-align: justify;
}

.al-icon {
  padding: 0.2em;
  margin: 0.2em;
  color: #1f335f85;
}

#al-section2 {
  color: #1f335f85;
}

#card-footer-al {
  text-align: right;
  font-size: 1.2em;
  color: #14213D;
}

#card-icon-al {
  color: navy;
}

.carousel-image-al {
  max-width: 80vw;
  height: auto;
}

.modal-btn-al {
  padding: 10px 0 0 0; 
  border: none;
  border-radius: 0.2em;
  text-align: center;
  font-family: 'Lato', sans-serif;
  color: grey;
  font-weight: 700;
  font-size: 30px;
  background-color: transparent;
}

.modal-btn-al:hover {
  color: #205375;
}

/* Responsive Query */

@media (max-width: 760px) {
  .cards-background {
    display: flex;
    align-content: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 2vw 0 ;
  }
  #cards {
    margin: 5vw;
  }
  .col {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px;
  }

}

@media (min-width: 2000px) {

  #cards {
    border-radius: 5px;
    width: 30vw;
    height: auto;
} 
}
