.contact {
    display: flex;
    margin-top: 1px;
    background-color: rgb(240, 241, 242);
    font-family: 'Lato', sans-serif;
    flex-direction: column;
    width: 100vw;
    align-items: center;
}


.contact h1 {
    letter-spacing: 1.0em;
}


.contact p {
 text-align: center;
 font-size: 1.2em;
 width: 70vw;
 text-transform: none;
 max-width: 90vw;   
 line-height: 1.6em;
 font-weight: 300;
}

#p-2 {
    letter-spacing: 0.2em;
    color: rgba(139, 141, 152, 0.85);
}

.contact-grid {
    display: grid;
    grid-template-columns: 30% 65%;
    grid-template-rows: 100% 100%;
    grid-column-gap: 5px;
    align-items: center;
    padding: 5% 3%;
    justify-content: space-evenly;
    justify-items: stretch;
} 

.contact-items {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    max-height: 400;
    height: 85%;
    padding-left: 2%;
    color: #1f335f;
    }

.icon-c {
    font-size: 30px;
    margin-bottom: 3%;
    color: orange;
    }

.contact-items h4 {
    font-weight: 700;
    text-transform: uppercase;
    font-size: smaller;
    text-align: left;
    padding: 0px;
}

.contact-items a {
    text-align: left;
}

.contact-items a {
    text-decoration: none;
    color: #1f335f;
}

.contact-items a:hover {
    color: rgba(139, 141, 152, 0.85);
}


@media (max-width:964px) {
    .contact-grid {
        margin: 0 auto;
        width: 100vw;
    }
    .contact-items {
       align-items: center;
    }  

    .contact-items a {
        text-align: center;
    }
}   

@media (max-width:700px) {
    .contact-grid {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        padding: 5% 0;

    }

    .contact h1 {
        margin-top: 10px;
        letter-spacing: 0.5em;
    }

    #p-1 {
        margin: -130px 0;
        visibility: hidden;
    }

    #p-2 {
        margin-top: 2em;
        letter-spacing: 0;
    }

    .contact-items {
        margin-left: 0px;
        text-align: center;
        margin-bottom: 0px;
        padding: 0 10%;
        }

    .contact-items a {
        text-align: center;
        }

        .icon-c {
        font-size: 30px;
        margin-bottom: 5px;
        text-align: center;
        }
}  

@media (max-width:670px) {
    #p-1 {
        margin: -100px 0;
    }
}

@media (max-width:550px) {
    #p-2 {
        margin-top: -1.8rem;
        ;
    }
}

@media (max-width:300px) {
    #p-2 {
        margin-top: -10em;
    }
}


/* div {
    border: 2px solid red;
} */
