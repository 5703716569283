
/* Main Container */
.home {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    position: relative;
    width: 100%;
    height: 100%;
    font-family: 'Lato', sans-serif;
    overflow-x: hidden;
    margin: none;
}

/* First Section - Image and Title */
.image-home {
    display: flex;
    flex-direction: column;
    align-content: center;
    height: 35vw;
    width: 100vw;
    background: url(../../assets/images/account8.jpeg);
    background-size: cover;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    position: relative;
    overflow-x: clip;
}

.text-home{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: center;
    background-color: rgba(128, 128, 128, 0.368);
    font-family: 'Playfair Display', serif;
    width: 100vw;
    min-height: 35vw;
}

.text-1 {
    padding: 5% 1% 2% 5%;
    text-align: left;
    background-color: #14213D;
    width: 65%;
    max-height: 40vw;

}

.home-name {
    font-size: 4.2em; 
    position: relative;
    text-align: left;
    color: white;
}

.text-home h3 {
    margin-top: 30px;
    font-size: 1.5;
    font-weight: 100px;
    color: white;
    letter-spacing: 1.1em;
    text-transform: capitalize;
    font-family: 'Lato', sans-serif;
    font-weight: 300;
}

.nav-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.navEbuttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 99vw;
}
  


/* Services Section */

.home-intro {
    width: 75%;
    text-align: center;
    padding: 0 5px 6vw 5px;
    font-family: 'Lato', sans-serif;
    font-size: 1.5em;
    color: #14213D;
    font-weight: 300;
    line-height: 1.6em;

}

.home-services {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: space-around;
    justify-content: space-evenly;
    align-items: center;
    padding: 5vw;
}

.home-services h4{
    color: rgb(1, 17, 38);
    letter-spacing: 1em;
}


.home-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: center;
    max-width: 95vw;
}


.home-list button {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    border: none;
    padding: 1.5em;
    margin-bottom: 2em;
    width: 10vw;    
    height: 10vw;
    border-radius: 100%;
    transition: bottom 0.5s cubic-bezier(0.42, 0, 0.58, 1), top 0.5s cubic-bezier(0.42, 0, 0.58, 1);

}


.home-list a {
    display: flex;
    border-radius: 0.2em;
    margin: 1em;
    padding: 2em;
    text-decoration: none;
    width: 19vw;
    height: 25vw;
    text-align: center;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: space-evenly;
    color: #1f335f;
    border-radius: 0.2em;
    text-align: center;
    font-family: 'Playfair Display', serif;
    font-weight: 600;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px 0px;
    transition: all 0.5s ease-out;

}


.home-list a:hover {
    bottom: 2%;
    right: auto;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 5px 15px 0px;
    background-color: #1f335f;
    color: white;
}

.react-icon {
    font-size: 7.5em;
    color: #1f335f;
}

#ic-house {
    color: orange;
}

#ic-al {
    color: #205375
}


/* Mission Section */


.home-mission {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 100%;
    margin: 5vw 0 1vw 0;
    padding: 1.2em;
    color: rgba(139, 141, 152, 0.85);
    font-family: 'Playfair Display', serif;
}

.home-mission p {
    line-height: 1.6em;
    width: 60%;
    font-size: 1.5em;
    padding: 2vw;
    font-weight: 300;
}

.missionValues {
    background-color: rgb(240, 241, 242);
    margin-bottom: 0.1rem;
}

.home-mission h4 {
    letter-spacing: 1em;
    margin-bottom: 2rem;
}

/* Values Section */
.margin-values {
    margin: 5% 0;
}

.home-values {
    border: 40px solid rgb(240, 241, 242);
    background: url(../../assets/images/account6.jpeg) no-repeat fixed;
    background-size: cover;
    background-position: center center;
    display: grid;
    grid-template-columns: 50% 50%;
    margin: 0;
    padding: 4em;
    width: 100vw;
    color: #fff;
}

.h-values {
   text-align: left;
   padding-top: 5%;
   padding-bottom: 5%;
   max-height: 100vw;
   background-color: #1f335fe2;
   ;
}

.h-values p {
    font-weight: 300;
    max-width: 85%;
}

.h-values p, .h-values h4 {
    padding: 1px 30px 60px 45px;
    font-size: 1.2em;
}

.h-values h4 {
    letter-spacing: 1em;
    margin-top: 10px;
}

#ul-values {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: space-around;
    max-height: 100%;
    justify-content: space-around;
}

#li-values {
    overflow: hidden;
    padding: 7px 0;
    text-transform: uppercase;
    text-align: center;
    color: #14213D;
    font-size: larger;
    letter-spacing: 0.3em;
    background: white;
}

/* Responsive Query */

@media (min-width:914) and (max-width: 1100px){
    .home-name {
     font-size: 3.5em;
     margin-top: -30px;
    }

    .text-1 {
    height: 55vw;
    width: 70vw;
    }

    .image-home {
     height: 40vw;
    }

    .text-home {
     height: 60vw;
    }

    .text-home p {
        width: 90vw;
        font-size:1.6em;
    }

    .text-home h3 {
        font-size: 1.2em;
    }

    .home-mission { 
       font-size: 1em;
    }

    .h-values p{
        font-size: 1.2em;
    }

    .home-list a {
        text-decoration: none;
        margin: 1em;
        width: 25vw;
        height: 250px;
        align-items: center;
    }

    .home-list button {
        width: 10vw;
        height: 10vw;
        padding: 2em;
        transition: all 1s;
    }
}


@media (max-width: 920px) {
    .home-name {
        margin-top: -20px;
        font-size: 3em;
    }

    .navEbuttons {
        width: 99.9vw;
    }
    
    .image-home {
        height: 45vw;
        width: 100vw;
    }

    .home-services h4,
    .home-mission h4,
    .home-mission p {
        font-size: 1em;
      }

    .home-list a{
        font-size: 0.8em;
        width: 25vw;
        height: 200px;
        font-weight: 200;
        justify-content: space-evenly;
    }

    .home-list button {
        width: 12vw;
        height: 12vw;
        padding: 1.2em;
        margin-top: 10px;
    }

    .home-intro  {
        font-size: 1em;
    }


    .text-home {
        height: 45vw;
        width: 100vw;
    }
    
    .text-1 {
        padding-top: 10%;
        width: 75vw;
        min-height: 35vw;
        margin-top: 0px;
    }
    
    .text-home h3 {
        font-size: 1em;
        letter-spacing: 1em;
      }

    .home-values {
        grid-template-columns: 100vw;
        margin: 2vw 0 0 0;
        align-content: space-evenly;
        justify-items: center;
        justify-content: space-evenly;
    }

    .h-values  {
        padding: 20px 50px;
    }

    .h-values  p {
        margin: 0;
        margin-bottom: 20px;
    
    }
    
    #ul-values {
        width: 80vw;
        font-size: smaller;
        margin-top: 20px;
    }

    #li-values {
        text-align: center;
        margin: 3px;
        font-size: smaller
    }

    .home-values p, .home-values h4 {
        font-size: 1.1em;
        padding: 10px 0px 10px 80px;
    }

    .h-values h4 {
        letter-spacing: 0.5em;
        text-align: left;
    }
}

@media(max-width: 820px) {
    .navEbuttons {
        flex-direction: column;
    }

    .home-list a span {
        font-size: 0.8em;
    }
}

@media (max-width:580px) {
    .home h4 {
        margin-top: 10px;
        letter-spacing: 0.1em;
    } 

    .text-home {
        width: 100vw;
        height: 90vw;
        flex-direction: column;
    }

    .image-home {
        min-height: 90vw;
    }

    .text-1 {
        margin-left: 0;
        padding: 11% 10% 10% 5%;
        width: 90vw;
        text-align: left;
        max-height: 85vw;
    }

 
    .navEbuttons {
        flex-direction: column;
    }

    .services-btn {
        display: flex;
        flex-direction: row;
    }
    
    /* Services */

    .home-name {
        margin-top: 0px;
        font-size: 2em;    
    }

    .home-name p {
        font-size: smaller;
    }

    .home-services {
        width: 100vw;
        padding-top: 10%;
        position: relative;

    }
    .home-services h4{
    font-size: 1em
    }

    .home-list {
        flex-direction: row;
        flex-wrap: wrap;
        margin: 20px;
    }

    .home-list a {
        text-decoration: none;
        margin: 0.5em;
        width: 90vw;
        height: 160px;
        box-shadow: none;
    }

    .home-list a span {
        font-size: 1em;
    }

    .home-list a:hover {
        bottom: 2%;
        right: auto;
        background-color: transparent;
        box-shadow: none;
        color: orange;
  }

    .home-list button {
        padding: 0.9em;
        width: 3.5em;
        height: 3.5em;
        transition: all 1s;
        font-size: 2em;
        margin: 0 0 0.6em 0;
    }

    .home-list button:hover {
        top: 0;
        bottom: 0;
        left: 100%;
        right: -100%;
        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.4) 0px 5px 15px 0px;
        border-radius: 10%;
        transform: translateY(-1%);
      }

    .react-icon {
        font-size: 3em;
    }

    /* Mission */
    .home-mission {
        padding: 10%;
        color: grey;
    }

    .home-mission p {
        margin-top: 10px;
        width: 100%;
        font-size: 0.9em;
    }

    /* Values */

    .home-values {
        grid-template-columns: 100vw;
        grid-template-rows: 50% 50%;
        margin: 2vw 0;
        width: 100vw;
    }

    .h-values {
        width: 100vw;
        z-index: 2;
        padding: 10px 50px 40px
    }

    .home-values h4 {
        font-size: 1em;
        text-align: left;    
        padding: 10px 10px;
        margin-left: 20px;
    }

    .home-values p {
        text-align: justify;
        font-size: 1em;
        padding: 10px 0;
        padding-right: 10px;
        margin: 10px 30px;
        max-width: 80%;
        }

    #ul-values {
        width: 100vw;
        text-align: center;
        padding: 10px 0;
        align-items: center;
        margin: 7px 0;
    }

    #li-values {
        font-size: smaller;
        text-align: center;
        width: 70vw ;
        font-weight: 400;
        border-bottom: none;
        padding: 7px 0;
        margin: 4px 0;
    }
}  

@media (max-width: 320px) {
    .home-name {
        font-size: 2em;
    }
    .text-home {
        width: auto;
        height: 110vw;
    }

    .image-home {
        min-height: 110vw;
    }

    .text-1 {
        font-size: 0.8em;
        margin-left: 0;
        padding: 10% 10% 10% 5%;
        width: 100vw;
        height: 80vw;
    }

    .home-list a {
        font-size: small;
        margin: 0;

    }

    .h-values {
        width: 90%;
        FONT-SIZE: smaller;
        padding: 2px 8px;    
    }

    .roller #rolltext {
        font-size: small;
    }

}


/*  Animate Text */

.main{
    display:flex;
    align-items:center;
    justify-content:center;
    text-align:center;
  }
  
  .roller{
    margin-top: 0.90vw;
    height: 4.125rem;
    line-height: 5rem;
    position: relative;
    overflow: hidden; 
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
    color: rgba(139, 141, 152, 0.521);
  }
  
  
  .roller #rolltext {
    font-family: 'Playfair Display', serif;
    position: absolute;
    top: 0;
    animation: slide 5s infinite;  
  }
  
  @keyframes slide {
    0%{
      top:0;
    }
    25%{
      top: -4rem;    
    }
    50%{
      top: -8rem;
    }
    72.5%{
      top: -12.25rem;
    }
  }
  
  @media screen and (max-width: 600px){
    h1{
    text-align:center;
    text-transform: uppercase;
    color: #F1FAEE; 
    font-size: 2.125rem;
  }
    
    .roller{
    height: 2.6rem; 
    line-height: 2.125rem;  
    }
    
    
    .roller #rolltext {  
    animation: slide-mob 5s infinite;  
  }
    
    @keyframes slide-mob {
    0%{
      top:0;
    }
    25%{
      top: -2.125rem;    
    }
    50%{
      top: -4.25rem;
    }
    72.5%{
      top: -6.375rem;
    }
  }
  }
