footer {
    background-color: rgb(240, 241, 242);
}

.social-media {
    text-align: center;
    color: #14213D;
}

#facebook-f:hover,
#linkedin:hover,
#blogue:hover {
    color: orange;
}

.foot-items {
    text-decoration: none;
    color: rgb(139, 141, 152);

}

.foot-items a {
  text-decoration: none;
  color: rgb(139, 141, 152);
}


#footer-dev {
    display: flex;
    font-size: small;
    align-items: flex-end;
    color: rgb(158, 153, 153);
    font-family: 'Playfair Display', serif;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
}

#footer-dev li {
    display: flex;
    font-size: small;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
}

#li-1 {
    margin-top: 3em;
}

#footerRinfo {
    text-align: center;
    margin: 0px;
    background-color: rgb(240, 241, 242);
    color: #14213D;
    font-size: 0.7em;
    /* font-family: "Playfair Display"; */
    font-family: 'Lato', sans-serif;
    text-transform: uppercase;
    padding: 0.2em;
}


.foot-items a:hover {
    text-decoration: underline;
}

.ulLogo {
    text-align: left;
    margin: 0px;
    padding: 0px;
}

@media screen and (max-width: 770px) {
    footer p,
    .text-uppercase {
        text-align: left;
        font-size: smaller;
        padding-left: 1px;
    }
}

@media (min-width: 992px) {
.justify-content-lg-between,
#footer-social {
    justify-content: center !important;
}
}