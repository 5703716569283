/* Links Grid */

.acco-links {
    height: 10vw;
    max-width: 90vw;
    text-align: center;
    margin: 10px 0;
}

.acco-grid {
  display: grid;
  grid-template-columns: 11rem 11rem 11rem 11rem;
  grid-gap: 1rem 0.5rem;
  justify-items: center;
  }

  .a-item {
    padding: 1em 2em;
    width: 11rem;
    background-color: rgb(240, 241, 242);
    border: none;
    text-transform: uppercase;
    border-radius: 0.2em;
    transition: all 0.5s ease-out;
    opacity: 1;
    font-size: 12px;
  }

  .a-item p {
    margin: 0px;
    text-align: center;
    transition: all 0.5s ease-out;
    color: navy;
    font-weight: 300;
  }

  .a-item:hover {
    opacity: 0.2;
  }

  .a-item:hover p{
    transform: translate(0, -10px);
  } 


  @media(max-width: 980px) {
    .acco-grid {
      grid-template-columns: 11rem 11rem 11rem 11rem;
      grid-template-rows: 2rem 2rem 2rem;
  }
  }


  @media(max-width: 550px) {
    .acco-links {
        width: 95vw;
    }

    .links-u h3 {
      font-size: smaller;
      margin-bottom: 1px;
      margin-top: 3%;
    }

    .acco-grid {
        grid-template-columns: 10rem 10rem;
        grid-gap: 0.2rem 0.2rem;
        justify-content: space-evenly;
      }
      .a-item {
        padding: 0.5em 0.5em;
        width: 160px;
      }
      .a-item p {
        font-size: smaller;
      }
  }
