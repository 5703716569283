.container {
    padding: 2% 15px;
    margin-right: auto;
    margin-left: auto;
}

.main_container {
    background-color: #fff;
    /* border-bottom-left-radius: 0.2em;
    border-bottom-right-radius: 0.2em;
    -webkit-box-shadow: 0 0 0.5px 0.5px #14213D;
    -moz-box-shadow: 0 0 0.5px 0.5px #14213D;
    box-shadow: 0 0 0.5px 0.5px #14213D; */
}

.col-xs-12 {
    width: 100%;
}

#catolog-row > * {
    display: flex;
    text-align: center;
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
}

.caixaCatalogo {
    text-align: center;
    border: none;
    height: 50px;
    width: 218px;
    float: left;
    padding: 20px 15px;
    position: relative;
    margin-bottom: 10px;
    margin-right: 10px;
    vertical-align: middle;
}

.imagemCatalogo {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: 185px;
    max-height: 48px;
    position: absolute;
    margin: auto;
}

@media (min-width: 1200px) {
.container {
    width: 1170px;
}
}

@media (min-width: 992px) {
.container {
    width: 970px;
}
}

@media (max-width: 768px) {
.container {
    width: 600px;
}
.caixaCatalogo {
    width: 100px;
}
.imagemCatalogo {
    max-width: 65px;
}

@media (max-width: 520px) {
    .container {
        width: 520px;
    }

    .caixaCatalogo {
        width: 70px;
        height: 40px;
        border: none ;
    }

    .imagemCatalogo {
        max-width: 65px;

    }
}

}