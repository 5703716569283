.emailBtn {
    min-width: 20%;
    padding: 10px;
    border: none;
    outline: none;
    background: rgb(222, 219, 219);
    font-size: 1rem;
    text-transform: uppercase;
    cursor: pointer;
    color: grey;
    border: 2px solid grey;
    border-radius: 0.4rem;
    text-align: center;
}

.emailBtn a {
    text-decoration: none;
    color: grey;
}


.emailBtn a:hover {
    color: white;
}

.emailBtn:hover {
    background:  #14213D;
    color: white;
    border: 2px solid #14213D;
    transition: 0.3s all linear;
}

@media (max-width: 960px) {
.emailBtn { 
    min-width: 25%;
}
}
@media (max-width: 580px) {
    .emailBtn { 
        width: 30%;
    }

}
