
.categoria .solucoes {
    position: absolute;
    left: 50px;
    right: 50px;
    bottom: 50px;
    top: 0;
    height: 70px;
    z-index: 1;
    margin: auto;
    opacity: 0;
}

.categoria {
    border-radius: 0.2em;
    overflow: hidden;
    position: relative;
    margin-bottom: 20px;
}

.categoria:before {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    max-height: 100%;
    max-width: 100%;
    left: 0;
    right: 0;
    background-color: #1f335fb5;
    opacity: 0.6;
    filter: alpha(opacity=90);
}

.categoria h4 {
    position: absolute;
    z-index: 2;
    color: #ffffff;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    font-size: 35px;
    font-weight: 200;
    height: 46px;
    width: max-content;
    width: fit-content;
    text-align: center;
    pointer-events: none;
    z-index: 2;
    transition: all 0.5s ease-out;
    opacity: 1;
}

.categoria:hover h4 {
    transform: translate(0, -40px);
    opacity: 0.2; 

}

@media (min-width: 1200px) {
.container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1140px;
}
}

@media (max-width: 992px) {
.container, .container-lg, .container-md, .container-sm {
    max-width: 960px;
}
.col-lg-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
}
.categoria h4 {
    font-size: 30px;
}
}

@media (max-width: 768px) {
.container, .container-md, .container-sm {
    max-width: 720px;
}
.categoria h4 {
    font-size: 26px;
}
}

@media (max-width: 576px)  {
.container, .container-sm {
    max-width: 540px;
}
.categoria h4 {
    font-size: 18px;
}

}