
.modal-container {
    display: flex;
    flex-direction: column;
}

#modal {
border-radius: 0.2em;
background-color: white;
padding: 1em 0;
border-radius: none;
}

.modal-body {
    max-width: 100vw;
} 

#modal-btn {
    padding: 0.5em 06em;
    background-color: grey;
    border: none;
}

#modal-title {
    color: #14213D;
}

#modal-title span {
    color: orange;
    margin-right: 12px;
    font-size: 1.5em;
  }

.modal-image {
    position: relative;
    overflow: hidden;
    max-width: 500px;
    width: 100%;
    height: 100%;
}

#contact-btn {
    background-color: orange;
    border: none;
}

#wts-btn {
    background-color: #25d366;
    border: none;
}

#contact-btn:hover {
    background-color: rgba(255, 166, 0, 0.401);
}

#wts-btn:hover {
    background-color: #25d36571;
}

.modal-description {
    font-family: 'Lato', sans-serif;
    margin: 2em 1em;
    text-align: justify;
    color: #14213D;
}

.modal-local-rent  {
    margin-right: 15px;
    text-align: right;  
    color: #14213D;
  }

  #rent-ico-modal {
    color: orange;
}


/* Al Modal */

.modal-comm {
    display: flex;
    flex-direction: column;
    margin-left: 20px ;
}

.modal-al-icon {
    color: #205375;
    background-color: transparent;
    margin-left: 15px;
    font-size: 1.2em;
}

.modal-header-al {
    display: flex;
    flex-direction: row;
    color: #1f335f64;
    border-top: none;
    border-radius: 0.2em;
    justify-content: space-around;
    align-items: center;
    font-size: 1.2em;
    padding: 1em 0;
    flex-wrap: wrap;
}



.card-description{
    visibility: visible;
  }

  .modal-local-al  {
    margin-right: 15px;
    text-align: right;
    color: #1f335f64;
  }

  #local-ico-modal {
    color: #205375;
  }


  /* Responsive map container */
.responsive-map-container {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%; 
}

/* Responsive map iframe */
.responsive-map-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#carousel-control-prev, 
#carousel-control-next {
  width: 100%;
  height: 100%;  
}

#booking-btn {
  background-color: #003b95;
  color: white;
  border: none;
  transition: opacity 0.7s;
}

#al-ico-modal {
  color:  #205375;
}

#airbnb-btn {
background-color: white;
color:  #FF5A5F;
border: 1px solid  #FF5A5F;
transition: opacity 0.7s;
}

#airbnb-btn:hover,
#booking-btn:hover {
  opacity: 0.2;
}

