.acco-container {
    display: flex;
    flex-direction: column;
    font-family: 'Lato', sans-serif;
    overflow-x: hidden;
    width: 100vw;
}

#acco {
    font-family: 'Lato', sans-serif;
    letter-spacing: 1em;

}

/*Account Section 1 */
.acco-section-wrap {
    height: 600px;
    background-image: url('../../assets/images/account19.jpeg') ;
    background-size: cover;
}

.acco-section-wrap h1 {
    text-align: center;
    letter-spacing: 0.5em;
    text-transform: uppercase;
    margin-top: 2rem;
    background: rgb(240 241 242 / 52%);
    color: #14213D;
}

.acco-section  {
    display: flex;
    flex-direction: row;
    background: rgb(240 241 242 / 70%);    border-radius: 0.2em;
    color:#14213D;
    width: 95vw;
    margin: 5em 5em 0 0;    
    padding: 7vw;
    flex-wrap: wrap;
    justify-content: flex-end;
}

.acco-back1 {
    width: 40vw;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-end;
    align-content: space-around;
    text-align: right;
    font-size: 1.4em;
}

.acco-back1 p {
    font-weight: 300;
}

.acco-container h3 {
    letter-spacing: 1em;
    margin-bottom: 1em;
}

.serv-container  {
    display: flex;
    flex-direction: row;
    justify-content: center;
}


/* Links */

.links-u {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: space-around;
    align-items: center;
    padding: 1%;
    background-color: #14213D;
}
.acc-grid {
    display: grid;
    grid-template-columns: 100vw;
    grid-template-rows: 5rem 5rem;
    justify-items: center;
    margin-top: 2rem;
    
}


.links-u h3 {
    margin-top: 2%;
    letter-spacing: 1em;
    text-align: center;
    color: white;
}

/* Account Section 2 */

.acco-section2 {
    padding: 6em;
    justify-content: center;
}

.acco-back2 {
    width: 50vw;
    text-align: center;
    color: rgba(139, 141, 152, 0.75);
}

.acco-back2 p {
    font-family: 'Playfair Display', serif;
    line-height: 1.6em;
    font-size: 1.3em;
}

/* IMPORTANT! Change for styled button */
.acco-back2 button {
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    margin-top: 3rem;
}

.acco-services  {
    display: grid;
    grid-template-columns: 25rem 26rem 24rem;
}

.accontant-icon {
    margin: 1.2em;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.icon-container {
    display: flex;
    justify-content: center; 
    align-items: center; 
    background-color: lightgrey;
    border-radius: 50%;
    color: navy;
    font-size: 60px;
    width: 100px; 
    height: 100px;
  }


/* Account Section 3 */

  .acco-back3 {
    display: flex;
    background-color: rgb(240, 241, 242);
    margin-bottom: -2rem;
    width: 100vw;
}

  .acco-back3 p {
    color: grey;
    font-size: 1.1em;
    padding: 2% 12%;
    border-radius: 20%;
    width: 50vw;
    text-align: justify;
    font-family: 'Lato', sans-serif;
  }

  
  /* CSS for the image column */
  .acco-icon {
    flex: 1 1;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-content: stretch;
    align-items: center;
    flex-wrap: wrap;
}
  
  .acco-img {
    width: 50%;
    max-width: 100%; /* Ensure the image doesn't exceed its container */
    max-height: auto;
}

  #serv {
    margin-top: 3rem;
  }

  @media (max-width: 1030px) {
    .acco-services {
        grid-template-columns: 23rem 21rem 19rem
    }
    .acco-back3 p {
        text-align: center;
        font-size: 0.9em;
        padding: 1% 6%;
    }
  }
  

  @media (max-width:950px) {
    .acco-services h5 {
        font-size: 0.9em;
        text-align: left;        
    }
    .grid-services {
        grid-template-columns: 17rem 15rem 15rem;
        grid-gap: 0.1rem 0rem;
    }

    .acco-services > *:nth-child(2) {
        grid-column: 1 / span 2; /* Occupy both columns */
        grid-row: 2; /* Place in the second row */
        width: 50vw; /* Set minimum height to 100vw for the second row */
        align-self: center;
        justify-self: center;
    }

    .col-acco li{
        letter-spacing: 0rem;
        line-break: 1.7em;
     }

     .acco-services {
        grid-template-columns: 25rem 23rem;
        grid-row: 90rem;
        grid-template-rows: 25rem;
        padding: 0 2rem; 
        margin-left: 0;
    }

    .col-acco {
        padding-left: 2em;
        padding-right: 2em;
        border-left: none;
        border-right: none;
        margin: 2em 1em 5em;
    }

  }

  @media (max-width:896px) {
    .acco-section-wrap {
        height: 450px;
    }    
    .acco-section-wrap h1 {
        font-size: 1.5em;
    }
    .acco-back1 {
        font-size: 1.1em;
    }

    .acco-container h3 {
        font-size: 1.3em;
        letter-spacing: 0.6em;
    }

    .acco-services h5 {
        font-size: 1em;
    }
    .grid-services {
        grid-template-columns: 50rem;
        align-content: stretch;
        align-items: start;
        justify-items: center;
        grid-gap: 0rem;

    }
    .col-acco  {
        border-bottom: none;
    }

    .col-acco li{
        letter-spacing: 0rem;
        line-break: 1.7em;
        margin-left: -30px;
        padding: 0;
     }

     .icon-container {
        width: 55px;
        height: 55px;
        font-size: 25px;
    }

    .acco-back3 p {
        padding: 1em;
        width: 50vw;
        text-align: center;
        font-size: smaller;      
}

    .links-u {
        max-height: 15rem;
        padding-bottom: 5%;
    }
    .acco-section2 {
        padding: 4em 3em 2em 3em;
    }

    .acco-back2 p {
        font-size: 0.9em;
  }

  .accontant-icon {
    margin-top: 1.5em;
    margin-left: 0;
    margin-bottom: 0;
    margin-right: 0;
  }
  .acco-back2 button {
    margin-top: 1.5rem;
    font-size: smaller;
}
.a-item {
    width: 10rem;
    font-size: 9px;
}
.acco-grid {
    grid-template-columns: 10rem 10rem 10rem 10rem;
    grid-template-rows: 1.2rem 1.2rem;
}
}

@media (max-width: 700px) {
    .a-item {
        width: 8rem;
        font-size: 9px;
    }
    .acco-grid {
        grid-template-columns: 8rem 8rem 8rem 8rem;
        grid-template-rows: 1.2rem 1.2rem;
        grid-gap: 0.1em, 0.1em
    }
}



  @media (max-width: 550px) {

    .acco-section-wrap {
    margin-top: -32px;
    }
    .acco-section-wrap h1 {
        text-align: center;
        letter-spacing: 0.2em;
        font-size: 1.5em;
    }
    .acco-section {
        margin: 5.5em 2em 0 0;    
    }
    .acco-back1 p {
        font-size: smaller;
        margin: 0px;
        width: 100%;
        ;
    }
    

    #acco {
        letter-spacing: 0.2em;
        margin: 1em;
    }
    /*liks and services */

    .acco-services {
        display: flex;
        flex-direction: column;
        grid-row: 100vw;
        padding: 0; 
        margin-left: 0;
    }

    .acco-services > *:nth-child(2) {
        width: 90vw;
    }

    .col-acco  {
        margin: 2rem 2em;
        border-bottom: 1px solid rgb(240, 241, 242);
        font-size: smaller;
    }

    .acco-services h5 {
        text-align: center;
    }
    .acco-services h3 {
        font-size: 1em;
    }

    .grid-services {
        display: grid;
        grid-template-columns: 25rem;
        grid-gap: 0.5rem 0.5rem;
        width: 100vw;
        border: 1rem solid rgb(240, 241, 242);
    }

    .col-acco li{
        font-size: 1rem;
        letter-spacing: 0rem;
        line-break: 1.7em;
        margin-left: -20px;
     }

     .acc-grid {
        margin-top: 1rem;
        
    }

    .links-u h3 {
        margin-top: 5%;
    }


    /* Section 2 */

    .acco-section2 { 
        padding: 0px;
        font-size: 1em;
        padding: 2em 3em;    
    }

    .acco-back2 {
        padding: 3em 2em;
        width: 99vw;
        font-size: smaller;
    }

    .acco-back2 p {
        font-size: 0.9em;
    }

    .a-item {
        width: 5rem;
        font-size: 8px;
    }

    .links-u{
        max-height: 13rem;
        padding: 3%;
    }

    .acco-grid {
        grid-template-columns: 5rem 5rem 5rem 5rem;
        grid-template-rows: 1.3rem 1.3rem;
        grid-gap: 0.2rem 0.1rem;
    }

    /* Section 3 */

    .acco-back3 {
    margin: -32px 0;
    max-width: 100vw;
    justify-content: center;

    } 
    .acco-back3 img { 
        width: 100vw;
        height: auto;
    }

    .acco-icon {
        width: 90vw;
        margin-top: 1em;
        flex-direction: column
    }
    #a-icon {
        margin-bottom: 0px;
    }
    
    .acco-back3 p{
        font-size: smaller;
        width: 85vw;
        padding: 1.2em 0.5em 2em;
        text-align: justify;
        }

    .acco-back1 h3 {
            letter-spacing: 0.5em;
        }
  }

  @media (max-width: 320px) { 
    .acco-section-wrap h1 {
        font-size: 1.2em;
    }
    .acco-container h3 {
        font-size: 1em;
    }
    .acco-section {
        margin: 2em 2em 0 0;
    }
    .acco-services h5 {
        font-size: 0.8em;
    }
    .col-acco li {
        font-size: 0.7rem;
    }
    .acco-grid {
    grid-template-columns: 5rem 5rem;
    grid-template-rows: 1.3rem 1.3rem 1.3rem;
    grid-gap: 0.2rem 0rem;
    }
    .acco-back2 p {
        font-size: 0.7em;
    }
    .acco-back3 p {
        font-size: 0.7em;
    }
  }