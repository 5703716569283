.s-btn {
    margin: 2px 1px;
    width: 50px;
    height: 50px;
    border: 2px solid #14213D;
    background-color: #14213D;
    color: #fff;
    transition: all 0.7s;
    position: relative;
    overflow: hidden;
  }

  .s-btn a {
    text-decoration: none;
    color: white;
  }

  .s-btn a:hover {
    opacity: 0.2;
  }

  .s-btn:hover {
    max-width: 320px;
    width: 300px;
    align-items: center;
    transition: opacity 0.10s;
    display: inline-flex;
    flex-direction: row;
    justify-content: space-around;
  }
  
  .button-text {
    opacity: 0;
    transition: all 0.5s;
  }

  .align-btns {
    margin-top: -1px;
    display: flex;
    flex-direction: column;
  }

  #btn-ico {
    font-size: 25px;
    margin-top: 10px;
    transition: all 0.7s;
  }

  #btn-ico:hover {
    margin-top: 0px;
  }
  
  .s-btn:hover .button-text {
    opacity: 1;
    font-family: 'Lato', sans-serif;
    letter-spacing: 0.9px;
    font-size: 1em;
    text-transform: capitalize;
    display: inline-block;
    transition: all 0.7s;
    width: 250px;
    padding-left: 1px;
    height: 10px;
}


@media (max-width: 980px) {
    .s-btn:hover .button-text {
        width: 210px;
        letter-spacing: 0px;
        font-size: 0.8em;
    }
}

@media (max-width: 820px) {
    .align-btns {
        flex-direction: row;
      }
}

@media (max-width: 750px) {
  .s-btn {
    margin: 5px 2px 2px 2px;
    width: 45px;
    height: 45px; 
    color: white;
  }

  .s-btn:hover {
    display: inline-flex;
    width: 300px;
    flex-direction: row;
    justify-content: space-around;
  }

  .s-btn:hover .button-text {
    letter-spacing: 0.7px;
    display: inline-block;
    font-size: 0.8em;
    transition: all 0.7s;
    width: 250px;
}

  #btn-ico {
    margin: 9px 0px;
  }

  .button-text {
    font-size: 10px;
    transition: none;
  }

}

@media (max-width: 700px) {
    .s-btn {
    margin: 3px 2px 0 0;
    width: 45px;
    height: 45px; 
    color: white;
  }
  .s-btn:hover .button-text {
    letter-spacing: 0.7px;
    display: inline-block;
    font-size: 0.8em;
    transition: all 0.7s;
    width: 250px;
}

#btn-ico:hover {
    margin-top: 9px;
}

.s-btn:hover {
    display: inline-flex;
    width: 280px;
    flex-direction: row;
    justify-content: space-around;
}
}

@media (max-width: 580px) {
  .s-btn {
    margin: 2px 1px 1px 1px;
    width: 40px;
    height: 40px;
    transition: all 0.7s;
  }

  .s-btn:hover {
    display: inline-flex;
    width: 230px;
    flex-direction: row;
    justify-content: space-around;
  }


  .s-btn:hover .button-text {
    letter-spacing: 0.2px;
    font-size: 0.7em;
    display: inline-block;
    transition: all 0.7s;
    width: 150px;
    padding-left: 0;
    height: 10px;
}

  #btn-ico {
    font-size: 19px;
    transition: all 0.7s;
    margin: 5px 4px 0 -7px;
  }

  .button-text {
    font-size: 5px;
    transition: all 0.7s;
  }
}
