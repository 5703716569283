/* Grid Accountant Services */
.grid-services {
    display: grid;
    grid-template-columns: 30rem 30rem 30rem;
    grid-gap: 5rem 0.5rem;
    border: 3rem solid rgb(240, 241, 242);
    border-top: none;
    align-content: space-evenly;
    align-items: start;
    justify-items: center;
    justify-content: space-evenly;

}

.acco-services h3 {
    text-align: center;
    letter-spacing: 1em;
    background-color: rgb(240, 241, 242);
    padding: 40px;
    margin-bottom: 0px;
}

.col-acco  {
    margin: 4rem 1em;
    padding: 0.2em;
    padding-left: 2.2em;
    padding-right: 2.2em;
    border-left: 0.1px solid lightgrey;
    border-right: 0.1px solid lightgrey;
}

.col-acco:hover  {
    background-color: #14213D;
    color: white;
}

.col-acco:hover h5  {
    color: white;
}

.col-acco h5 {
    text-transform: uppercase;
    letter-spacing: 0.1em;
    text-align: center;
    font-weight: bold;
    margin-bottom: 2rem;
    margin-top: 0.8rem;
    text-align: center;
    color: #14213D;
}


.col-acco ul{
    list-style: none;
}

.col-acco li::before {
    content: "•  ";  
    color: orange;
    font-weight: bold; 
    display: inline-block; 
    width: 1em; 
    margin-right: 10px;
  }

.col-acco li{
    font-size: 1rem;
    text-align: left;
    letter-spacing: 0.1rem;
    line-break: 1.5em;
    text-align: left;
    list-style-position: inside;
    margin-left: -30px;
    font-family: 'Lato', sans-serif;
    font-weight: 300;
 }


