

.topnav {
  display: flex;
  padding: 0 0.5vw;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  font-family: 'Lato', sans-serif;
  background-color: rgb(240, 241, 242);
  align-items: center;
  justify-content: space-evenly;
}
  
  .topnav a {
    float: left;
    display: block;
    color:#14213D;
    text-align: center;
    padding: 1.5vw 3vw;
    text-decoration: none;
    font-size: 17px;
    z-index: 999;
  }
  
  .active {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 19px;
  }

  /* Logo */
  #logo {
    padding: 1.5vw 35vw 1.5vw 1vw;
    border-bottom: none;
    transition: none;
    font-size: x-large;
    color: #14213D;
    font-weight: 900;
    z-index: 999;
    border: none
  }

  #logo span {
    color: orange;
    margin-right: 12px;
    font-size: 1em;
    border: none
  }

  #logo:hover,
  #logo:active,
  #logo::before {
    border: none;
    text-decoration: none;
  }

/* Dropdown span */
#dropdown-ico {
  margin-left: 12px;
  font-size: 15px;
  transition: opacity 1s ease;
  z-index: 999;
}

.dropdown:hover #dropdown-ico {
  transform: rotate(180deg);
  opacity: 0;
  z-index: 999;
}
  
  /* Icon for Smaller Devices */
  .topnav .icon {
    display: none;
  }
  
  /* Dropdown */
  .dropdown {
    position: relative;
    float: left;
    overflow: clip;
    z-index: 999;
  }
  
  .dropdown .dropbtn {
    font-size: 17px;
    border: none;
    outline: none;
    color:#14213D;
    padding: 1.5vw 3vw;
    background-color: inherit;
    font-family: inherit;
    margin: 0;
  }
  
  .dropdown-content {
    display: none;
    position: fixed;
    background-color: rgb(240, 241, 242);
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    overflow-x: visible;
  }
  
  .dropdown-content a {
    float: none;
    color: rgb(147, 144, 144);
    padding: 1.5vw 5vw;
    text-decoration: none;
    display: block;
    border-bottom: 1px solid;
    transition: all 0.5s ease;
  }
  
  .topnav a:hover, .dropdown:hover .dropbtn {
    color: rgb(147, 144, 144);
    transition: opacity 1s ease;
    transition: all 0.5s ease;
  }
  
  .dropdown-content a:hover {
    background-color: rgb(191, 191, 191);
    color: #14213D;
    transition: max-height 0.3s ease;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
    margin-top: 5.5px;
  }

@media screen and (min-width: 880px) {
    #i-contact {
      padding: 0.3em 1.8em;
      border-radius: 2px;
      background-color: orange;
    }
  
    #i-contact:hover {
      background-color: #e89a0a;
      color: white;
    }
  
}

  @media screen and (max-width: 880px) {
    .topnav {
      display: flow-root;
      width: 100vw;
      background-color: rgb(240, 241, 242);
  }

    .topnav a:not(:first-child), .dropdown .dropbtn {
      display: none;
    }
    .topnav a.icon {
      border-bottom: none;
      float: right;
      display: block;
      font-size: 30px;
      padding: 3px 5vw;
      transition: all 1s;
    }

    .topnav a,
    .dropdown .dropbtn {
        padding: 1em 2em;
        text-align: left;
    }
    #logo {
      padding: 0.5em 3.5vw;
    }
  }
  
  @media screen and (max-width: 880px) {
    .topnav.responsive {position: relative;}
    .topnav.responsive a.icon {
      position: absolute;
      right: 0;
      top: 0;
      padding-right: 5vw;
      transition: all 1s;
    }
    .topnav.responsive a {
      float: none;
      display: block;
      text-align: left;
    }
    .topnav.responsive .dropdown {float: none;}
    .topnav.responsive .dropdown-content {position: relative;}
    .topnav.responsive .dropdown .dropbtn {
      display: block;
      width: 100%;
      text-align: left;
    }
  }