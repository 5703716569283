
.rent {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    overflow-x: hidden;
    width: 100vw;
}

/* Intro Section */

#rent-text {
display: flex;
flex-direction: column;
align-items: center;
align-content: center;
padding: 10px 0;
text-align: center;
font-family: 'Lato', sans-serif;
}

#rent-text h1 {
    width: 100vw;
    color: #14213D;
    letter-spacing: 0.5em;
    text-transform: uppercase;
    color: #14213D;
    margin-top: 2rem;
    overflow-x: hidden;
    background: rgb(240 241 242 / 42%);
}

.rent-intro1 {
    display: flex;
    width: 100vw;
    padding: 0 8vw 15vw;
    position: relative;
    background-image: url(../../assets/images/rentt7.jpeg);
    background-size: auto 60vw;
    background-repeat: no-repeat;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-around;
    align-items: center;
}

.rent-ball {
    font-weight: 300;
    margin: 6% -30% 2% 30% ;
    overflow-x: hidden;
}

.rent-intro1 p {
    border-radius: 0.2em;
    background-color: rgba(255, 166, 0, 0.432);
    color:  #14213D;
    padding: 4vw;
    font-size: 1.2em;
    font-weight: 500;
    font-family: 'Lato', sans-serif;
    text-align: right;
    width: 50vw;

}

/* Section 2 */
.rent-intro {
    display: flex;
    flex-direction: row;
    width: 100vw;
    padding: 5vw;
    align-content: space-around;
    justify-content: space-around;
    align-items: center;
    background-color: #14213D;
    font-weight: 300;
}

.rent-intro p{
    font-size: 1.5em;
    width: 40vw;
    text-align: center;
    margin: 0px;
    color: white;
}

#icon-rent {
    font-size: 150px;
    color: white;
    background-color: orange;
    padding: 20px;
    border-radius: 100%;
}

/* Cards Section */

.rent-houses {
    padding: 5em 0 0;
}

.rent-houses h4 {
    letter-spacing: 1.2em;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    color: #14213D;
    margin-bottom: 2em;
}

/* Contact Sectio */

.rent-contact {
    width: 100vw;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    padding: 3vw 0px;
    background-color: rgb(240, 241, 242);
    align-content: center;
    justify-content: flex-start;
}

.rent-contact h4 {
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    margin-bottom: -50px;
}


@media (max-width: 1070px) {
    .rent-intro1 {
        display: flex;
        flex-direction: column;
        background-size: auto 70vw;
    }

    #rent-text h1 {
        padding: 0 3em;
    }

    .rent-intro1 p{
        font-size: 1.1em;
        color: white;
        padding: 3vw;
        width: 70vw;
        text-align: center;
        margin-top: 5em;
    }

    .rent-ball {
        margin:0 ;
    }

    .rent-intro p {
        font-size: 1.1em;
    }
}

@media (max-width: 920px) {

    #rent-text h1 {
        letter-spacing: 0.3em;
        font-size: 1.5em;
    }

    .rent-intro1 {
        background-size: auto 80vw;
    }
    .rent-intro1 p {
        margin-top: 5em;
    }

    .rent-intro1 p,
    .rent-intro p {
        font-size: 1em;
    }
    #icon-rent {
        font-size: 110px;
        color: white;
        background-color: orange;
        padding: 20px;
        border-radius: 100%;
    }
    .rent-houses h4 {
        letter-spacing: 0.6em;
    }
    .rent-contact button{
        font-size: 1em;
    }
}

@media (max-width: 660px) {
    .rent-intro1 {
        background-image: url('../../assets/images/rentt7-2.jpg');
        background-size: 100% auto;
        flex-wrap: nowrap;
        height: 700px;
    }

    #rent-text h1, 
    .rent-houses h4 {
        letter-spacing: 0.5em;
    }
}

@media (max-width: 500px) {
#rent-text h1 {
    letter-spacing: 0.2em;
}

#rent-text h1, .rent-houses h4 {
    letter-spacing: 0.2em;
}

.rent-services {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: space-between;

}

.rent-intro1 {
    background-image: url('../../assets/images/rentt7-2.jpg');
    background-size: auto 110%;
    flex-wrap: nowrap;
    height: 700px;
}

#icon-rent {
    font-size: 150px;
    padding: 30px;
    margin-bottom: 20px;
}

.rent-ball {
    margin: 0 ;
}

.rent-intro1 p {
    font-size: 1em;
    width: 90vw;
    text-align: center;
}

.rent-intro  {
    flex-direction: column;
}

/* Section Two */

.rent-intro p{
    font-size: 1em;
    width: 80vw;
    margin: 30px;
}

.rent-contact {
    width: 100vw;
    text-align: center;
}

.rent-contact h4 {
 text-align: center;
}
}

@media (max-width: 320px) {
    #rent-text h1, .rent-houses h4 {
        font-size: 1.2em;
    }
    .rent-intro1 p, .rent-intro p {
        font-size: 0.9em;
    }
}
